import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { getFormValues } from '../Misc/Misc';
import ActionSuccessfulPage from "./ActionSuccessfulPage";
import { PostAPIMethodStateless } from '../../APIMethods/PostAPIMethod';

function ForgotPassword(props) {
    const [currentView,setCurrentView] = useState(1);
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getFormValues();
        console.log(formData)

        PostAPIMethodStateless("reset-password/",formData,setRequests)
    }

    useEffect(()=>{
        if (request.data?.success) {
            setCurrentView(2)
        }
    },[request.data,])

    return (
        <div className='relative h-full py-16 md:py-28 px-2'>
            {
                currentView === 1?
                <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
                    <div className='text-center text-white'>
                        <h2 className='text-2xl font-semibold md:text-3xl'>Forgot Password ?</h2>
                        <p className='text-sm font-normal text-gray-200 md:text-base'>Don't worry it can happen to the best of us,we will help you recover it</p>
                    </div>
                    {
                        request.error && <p className='text-center text-red-400 font-medium text-sm md:text-base'>Account not found</p>
                    }
                    <form onSubmit={handleSubmit} className='w-full'>
                        <div className="h-12 input-group md:h-14">
                            <input type="email" id="email" name='email' placeholder='your gateway email' required className="input h-full" autoComplete='off'/>
                            <label htmlFor="email" className="input-label">Email</label>
                        </div>

                        <div className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                            <button className='text-white w-full h-full font-medium text-base md:text-xl'>Send recovery link</button>
                        </div>
                    </form>

                    <div className='w-full text-right flex flex-col space-y-2'>
                        <Link to={"/login"} className="text-white font-normal text-sm md:text-base">Remembered password? <span className='text-[#ea7938]'>Login</span></Link>
                    </div>
                </div>

                :
                <ActionSuccessfulPage title={"Password reset link has been sent"}/>
            }
        </div>
    );
}

export default ForgotPassword;