import React from 'react';
import SellTableInfo from './OrderInfo/SellTableInfo';


function SellOrderView({orderData}) {
    return (
        <>
            <table className='w-full'>
                <thead className='bg-[#FAFAFA] w-full h-20 flex place-content-center p-5'>
                    <tr className='grid grid-cols-5 justify-evenly w-full h-full items-center text-xs md:text-base md:grid-cols-8'>
                        <th>Customer Name</th>
                        <th>Proof</th>
                        <th>Coin</th>
                        <th>Amount</th>
                        <th className='hidden md:block'>Bank Name</th>
                        <th className='block md:hidden'>Bank Info</th>
                        <th className='hidden md:block'>Account No</th>
                        <th className='hidden md:block'>Timestamp</th>
                    </tr>
                </thead>

                <tbody className='w-full'>
                    {
                        orderData?.map((item,key)=>{
                            return(<SellTableInfo data={item} key={key}/>)
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

export default SellOrderView;