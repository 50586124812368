import React,{useState,useEffect} from 'react';
import { useTitle } from '../Misc/Misc';
import { Link,useLocation } from 'react-router-dom';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import BuyOrderView from './UserTradesComponets/BuyOrderView';
import SellOrderView from './UserTradesComponets/SellOrderView';


function UserTrades(props) {
    const search = useLocation().search;
    useTitle("Gateway Exchange - Customer Trades");
    const [filterBy,setFilterBy] = useState("Pending");
    const [currentView,setCurrentView] = useState("Buy");
    const userTrades = GetAPIMethods(`customer-orders/?order=${currentView}&status=${filterBy}`);


    useEffect(()=>{
        const params = new URLSearchParams(search);
        const order = params.get('order');
        const status = params.get('status');

        if (order && status) {
            setFilterBy(status)
            setCurrentView(order)
        }
       
    },[search])

    

    return (
        <div className='w-full mx-auto my-32 md:w-[90%]'>
            { userTrades.loading && <GatewayLoader/>}
            <div className='text-[#828282] flex w-full items-center justify-center font-medium text-base md:text-2xl'>
                <div onClick={()=>{setCurrentView("Buy")}} className={`${currentView==="Buy"? "text-black":""} border-r-2 border-r-[#828282] px-5`}>
                    <Link to={"?order=Buy"}>Buy Orders</Link>
                </div>
                <div onClick={()=>{setCurrentView("Sell")}} className={`${currentView==="Sell"? "text-black":""} border-l-2 border-l-[#828282] px-5`}>
                    <Link to={"?order=Sell"}>Sell Orders</Link>
                </div>
            </div>


            <div className='w-full mt-11'>
                <div className='w-full'>
                    <div className='flex justify-evenly max-w-lg items-center place-content-center'>
                        <Link to={`?order=${currentView}&status=Pending`}>
                            <div onClick={()=>{setFilterBy("Pending")}} className={`${filterBy === "Pending"? "border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Pending ({userTrades.data?.pending})</h3>
                            </div>
                        </Link>

                        <Link to={`?order=${currentView}&status=Completed`}>
                            <div onClick={()=>{setFilterBy("Completed")}} className={`${filterBy === "Completed"? "border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Approved ({userTrades.data?.completed})</h3>
                            </div>
                        </Link>

                        <Link to={`?order=${currentView}&status=Declined`}>
                            <div onClick={()=>{setFilterBy("Declined")}} className={`${filterBy === "Declined"? "border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Declined ({userTrades.data?.declined})</h3>
                            </div>
                        </Link>
                    </div>

                    {
                        currentView === "Buy"?
                        <BuyOrderView orderData={userTrades.data?.trades}/>
                        :
                        <SellOrderView orderData={userTrades.data?.trades}/>
                    }
                    
                </div>

            </div>
            
        </div>
    );
}

export default UserTrades;