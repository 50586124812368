import React,{useState,useEffect} from 'react';
import { Link,useLocation } from 'react-router-dom';


function Footer(props) {
    const { pathname } = useLocation();
    let currentPath = pathname.split("/");
    const [showFooter,setShowFooter] = useState(false);

    useEffect(() => {
        const showFooterToURLs = ['',];
        setShowFooter(showFooterToURLs.includes(currentPath[1]));

    }, [currentPath,]);

    return (
        <>
            {
                showFooter &&
           
                <div className='w-full bg-[#3b3343] p-5 md:p-10'>
                
                    <div className='max-w-[150px] w-full -ml-2'>
                        <Link to={"/"}>
                            <img src="/Image/logo.svg" alt="" className='w-full h-full'/>
                        </Link>
                    </div>
                

                    <div className='flex flex-col space-y-2 text-white font-medium text-sm'>
                        <div className='cursor-pointer'>
                            <h2>Support</h2>
                        </div>
                        <div className='cursor-pointer'>
                            <h2>Privacy policy</h2>
                        </div>
                    </div>


                    <div className='mt-5 mb-1 w-full text-center text-white font-normal text-xs md:text-sm'>
                        <h4>Copyright © 2022 Gateway Network</h4>
                    </div>
                    
                </div>
            }
        </>
    );
}

export default Footer;