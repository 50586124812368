import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import GatewayLoader from '../Loader/GatewayLoader';
import ActionSuccessfulPage from "./ActionSuccessfulPage";
import { useTitle,getFormValuesFromEvent } from '../Misc/Misc';
import { PostAPIMethodStateless } from '../../APIMethods/PostAPIMethod';

function SignUpPage(props) {
    useTitle("Gateway Exchange - Create Account");
    const [currentView,setCurrentView] = useState(1);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})


    const handleSubmit =(e)=>{
        e.preventDefault();

        const formData = getFormValuesFromEvent(e.target)
        PostAPIMethodStateless("create-account/",formData,setRequests,false)
        
    }

    useEffect(()=>{
        if (request.data?.success) {
            setCurrentView(2)
        }
    },[request.data,])



    return (
        <div className='relative h-full py-16 md:py-28 px-2'>
            {request.loading && <GatewayLoader/>}
            {
                currentView === 1?
           
                <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
                    <div className='text-center text-white'>
                        <h2 className='text-2xl font-semibold md:text-3xl'>Create an Account</h2>
                        <p className='text-sm font-normal text-gray-200 md:text-base'>Start your journey with us by creating an account</p>
                    </div>

                    <form onSubmit={handleSubmit} className='w-full'>
                        
                        <div className="min-h-[3rem] input-group md:min-h-[3.5rem]">
                            <input type="text" id="first_name" name='first_name' placeholder='First Name' required className="input h-full" autoComplete='off'/>
                            <label htmlFor="first_name" className="input-label">First Name</label>
                        </div>
                        

                        <div className="min-h-[3rem] input-group md:min-h-[3.5rem]">
                            <input type="text" id="surname" name='surname' placeholder='Surname' required className="input h-full" autoComplete='off'/>
                            <label htmlFor="surname" className="input-label">Surname</label>
                        </div>

                        <div className="min-h-[3rem] input-group md:min-h-[3.5rem]">
                            <input type="email" id="email" name='email' placeholder='Email' required className="input h-full" autoComplete='off'/>
                            <label htmlFor="email" className="input-label">Email</label>
                            <p className='text-red-600 text-xs md:text-sm'>{request.data?.email}</p>
                        </div>

                        <div className="min-h-[3rem] input-group md:min-h-[3.5rem]">
                            <input type="password" id="password" name='password' placeholder='Password' required className="input h-full" autoComplete='off'/>
                            <label htmlFor="password" className="input-label">Password</label>
                        </div>

                        <div className="min-h-[3rem] input-group md:min-h-[3.5rem]">
                            <input type="text" id="referred_by" name='referred_by' placeholder='referred by' className="input h-full" autoComplete='off'/>
                            <label htmlFor="referred_by" className="input-label">Referred By</label>
                        </div>

                        <div className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                            <button className='text-white w-full h-full font-medium text-base md:text-xl'>Sign Up</button>
                        </div>
                    </form>

                    <div className='w-full text-right flex flex-col space-y-2'>
                        <Link to={"/login"} className="text-white font-normal text-sm md:text-base">Already have an account? <span className='text-[#ea7938]'>Login</span></Link>
                    </div>
                </div>

                :
                <ActionSuccessfulPage title={"Please confirm your email"}/>
            }
        </div>
    );
}

export default SignUpPage;