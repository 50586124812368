import { useEffect } from "react";

export function filterByValue(array,key, string) {
    return array.filter((data)=> data[key] === string)
}


export const numberComma = (number)=>{
    return(String(number).split(/(?=(?:\d{3})+$)/).join(","))
}


export const prettyNumberInput = (e) =>{
    e.target.value = numberComma(e.target.value.replace(/,/g,""))
}

export const returCurrencyEquivalent = (currency,rate,amount)=>{
  if (currency==="USD") {
      return((amount*rate).toFixed(0))
  } else {
      return((amount/rate).toFixed(0))
  }
}

export const checkIfNum = (value) =>{
    var regex = /^\d*\.?\d*$/;
    return(regex.test(value))
}

export const numberOnly = (e)=>{
    const value = e.target.value.replace(/,/g,"");
    var isNum = checkIfNum(value);
    if(!isNum) {
        e.target.value = "";
    }
}

export function prettyNumber(number, decimals=1) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return number >= item.value;
    });
    return item ? (number / item.value).toFixed(decimals).replace(rx, "$1") + item.symbol : "0";
}

export const getFormValues = () =>{
    let formData = {}
    const formElem = document.querySelector('form')
    let inputElements = formElem.querySelectorAll('input')
    inputElements.forEach((input,inputID)=>{
        formData[input.name] = input.value
    })

    return(formData)
}


export const getFormValuesFromEvent = (formElem) =>{
  let formData = {}
  // const formElem = document.querySelector('form')
  let inputElements = formElem.querySelectorAll('input, select')
  inputElements.forEach((input,inputID)=>{
      formData[input.name] = input.value;
  })

  return(formData)
}

export const getAdvancedFormValues = () =>{
  let formData = {}
  const formElem = document.querySelector('form')
  let inputElements = formElem.querySelectorAll('input, select')
  inputElements.forEach((input,inputID)=>{
      formData[input.name] = input.value;
      
      if (input.type === "select-multiple") {
        formData[input.name] = [];
        var options = input.options;
        for (var i=0, iLen=options.length; i<iLen; i++) {
      
          if (options[i].selected) {
            formData[input.name].push(options[i].value || options[i].text);
          }
        }
      }
  })

  return(formData)
}


export function useTitle(title){
    useEffect(() => {
      const prevTitle = document.title;
      document.title = title;
  
      return () => {
        document.title = prevTitle;
      };
    },);
  }

export function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}


export const copyAddress = (address) =>{

  if (! navigator.clipboard) {
    
    fallbackCopyTextToClipboard(address);
    alert('Copied Wallet Address !!!');
    return
  }

  window.navigator.clipboard.writeText(address);


}
  
export const copyReferralID = (ID) =>{

  if (! navigator.clipboard) {
    
    fallbackCopyTextToClipboard(ID);
    alert('Copied Referral ID !!!');
    return
  }

  window.navigator.clipboard.writeText(ID);


}

export const copyAccountNumber = (number) =>{

  if (! navigator.clipboard) {
    
    fallbackCopyTextToClipboard(number);
    alert('Copied Account Number !!!');
    return
  }

  window.navigator.clipboard.writeText(number);


}

export const copyVerificationNumber = (number) =>{

  if (! navigator.clipboard) {
    
    fallbackCopyTextToClipboard(number);
    alert('Copied Verification Number !!!');
    return
  }

  window.navigator.clipboard.writeText(number);


}