import React,{useState,useEffect} from 'react';
import { getFormValues,useTitle } from '../Misc/Misc';
import { Link,useNavigate } from 'react-router-dom';
import { AuthPost } from '../../APIMethods/PostAPIMethod';
import GatewayLoader from '../Loader/GatewayLoader';

function LoginPage(props) {
    const navigateTo = useNavigate();
    useTitle("Gateway Exchange - Login");
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    useEffect(()=>{
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('isAuthenticated')
        localStorage.removeItem('verified')
        localStorage.removeItem('is_admin')
    },[])

    const handleSubmit =(e)=>{
        e.preventDefault();

        const formData = getFormValues();

        AuthPost('/authenticate/',formData,setRequests)
    }

    useEffect(()=>{
        if (request.data && !request.error) {
            navigateTo("/dashboard")
        }
    },[request,navigateTo]);

    return (
        <div className='relative h-full py-16 md:py-28 px-2'>
            {request.loading && <GatewayLoader/>}
            <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
                <div className='text-center text-white'>
                    <h2 className='text-2xl font-semibold md:text-3xl'>Welcome</h2>
                    <p className='text-sm font-normal text-gray-200 md:text-base'>Please enter you login details to proceed</p>
                </div>
                {
                    request.error && <p className='text-center text-red-400 font-medium text-sm md:text-base'>{request.data?.detail}</p>
                }
                <form onSubmit={handleSubmit} className='w-full'>
                    <div className="h-12 input-group md:h-14">
                        <input type="email" id="email" name='email' placeholder='email' required className="input h-full" autoComplete='off'/>
                        <label htmlFor="email" className="input-label">Email</label>
                    </div>

                    <div className="h-12 input-group md:h-14">
                        <input type="password" id="password" name='password' placeholder='password' required className="input h-full" autoComplete='off'/>
                        <label htmlFor="password" className="input-label">Password</label>
                    </div>

                    <div className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                        <button className='text-white w-full h-full font-medium text-base md:text-xl'>Login</button>
                    </div>
                </form>

                <div className='w-full text-right flex flex-col space-y-2'>
                    <Link to={"/forgot-password"} className="text-white font-normal text-sm md:text-base">Forgot password?</Link>
                    <Link to={"/signup"} className="text-white font-normal text-sm md:text-base">New to Gateway? <span className='text-[#ea7938]'>SignUp</span></Link>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;