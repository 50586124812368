import React,{useState,useEffect} from 'react';
import { Link,useLocation } from 'react-router-dom';


function IndexHeader(props) {
    const { pathname } = useLocation();
    let currentPath = pathname.split("/");
    const [showHeader,setShowHeader] = useState(false);

    useEffect(() => {
        const showHeaderToURLs = ['',"login","signup","success","forgot-password"];
        setShowHeader(showHeaderToURLs.includes(currentPath[1]));
        if (showHeaderToURLs.includes(currentPath[1])) {
            document.documentElement.style.background = "";
        }

    }, [currentPath,]);


    return (
        <>
            {
                showHeader &&
           
                <div className='w-full absolute z-10 left-0 right-0 p-5 mx-auto md:w-4/5 2xl:w-4/6'>
                    <div className='max-w-[150px] w-full -ml-2'>
                        <Link to={"/"}>
                            <img src="/Image/logo.svg" alt="" className='w-full h-full'/>
                        </Link>
                    </div>
                </div>
            }
        </>
    );
}

export default IndexHeader;