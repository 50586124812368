import { useTitle } from '../Misc/Misc';
import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import GatewayLoader from '../Loader/GatewayLoader';
import TradeInfoList from './DashboardComponents/TradeInfoList';
import OrderInfoPopUp from './DashboardComponents/OrderInfoPopUp';
import GetAPIMethods,{GetAPIMethodsStateless} from '../../APIMethods/GetAPIMethod';


function Dashboard(props) {
    useTitle("Gateway Exchange - Dashboard");
    const navigateTo = useNavigate();
    const [orderID,setOrderID] = useState(null);
    const [canTrade,setCanTrade] = useState(false);
    const [showPopUp,setSHowPopUp] = useState(false);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const TradeHistory = GetAPIMethods("/orders/");

    const handlePopUp = (orderID)=>{
        setOrderID(orderID);
        setSHowPopUp(!showPopUp);
    }

    useEffect(()=>{
        
        const verified = localStorage.getItem('verified')
        if (verified === 'true') {
            setCanTrade(true)
        }
    },[])

    useEffect(() => {
        var call = true
        if(call && orderID !== null){
            GetAPIMethodsStateless(`/order-info/${orderID}`,setRequests);
        }
        return () => {
            call = false
        }

    }, [orderID])
    
    return (
        <>
            {
                showPopUp && !request.loading && <div onClick={()=>{setSHowPopUp(!showPopUp)}} className='popup-shadow'></div>
            }
            {
                TradeHistory.loading | request.loading && <GatewayLoader/>
            }
            <div className='w-full pt-32'>
                
                <div className='w-full max-w-xl mx-auto'>
                    <div className='text-center'>
                        <h3 className='text-lg font-semibold md:text-2xl'>Good Evening, <span className='text-[#ec752f]'>{TradeHistory.data?.user?.username !== ""?TradeHistory.data?.user?.username:TradeHistory.data?.user?.firstname}</span> </h3>
                        <p className='text-[#ff9a19] font-medium text-xs md:text-sm'>It's always good to see you.</p>
                    </div>

                    <div className='grid grid-cols-2 gap-5 h-11 mx-auto w-full my-5 p-5 place-content-center md:h-14'>
                        <div  onClick={()=>{if (canTrade) {
                            navigateTo("/trade")
                        }}} className='p-5 rounded-xl bg-[#ed752f] cursor-pointer h-full'>
                            <div>
                                <h3 className='text-white text-center font-medium text-sm md:text-lg'>Buy Crypto</h3>
                            </div>
                        </div>
                        <div onClick={()=>{if (canTrade) {
                            navigateTo("/trade#sell")
                        }}} className='p-5 rounded-xl borde bg-[#3b3343] cursor-pointer h-full'>
                            <div>
                                <h3 className='text-white text-center font-medium text-sm md:text-lg'>Sell Crypto</h3>
                            </div>
                        </div>
                    </div>

                    {!canTrade && <p className='text-center text-red-600 font-medium text-sm md:text-base'>Please verify your account in order to trade</p>}
                </div>


                <div className='mt-20 md:p-5'>
                    <div>
                        <h3 className='font-bold text-center text-sm md:text-lg'>Order History</h3>
                    </div>
                    <div className='border-2 border-gray-200 border-b-0 rounded-2xl rounded-b-none p-2'>
                        {
                            TradeHistory.data?.history?.map((trade,id)=>{
                                return(<TradeInfoList key={id} data={trade} showPopUp={handlePopUp}/>)
                            })
                        }
                        {

                            TradeHistory.data?.history?.length === 0 &&
                            <div className='max-w-[130px] w-full mx-auto my-24'>
                                <svg fill="none" stroke="#cbd5e1" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
                                </svg>

                                <p className='text-center font-medium text-gray-400 text-sm md:text-base'>There are no trades yet</p>
                            </div>
                        }
                    </div>

                </div>
            </div>

            {
                showPopUp && <OrderInfoPopUp orderInfo={request} onClick={()=>{setSHowPopUp(!showPopUp)}}/>
            }
        </>
    );
}

export default Dashboard;