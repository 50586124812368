import React from 'react';
import { copyAccountNumber } from '../../Misc/Misc';
import { GetAPIMethodsStateless } from '../../../APIMethods/GetAPIMethod';

export default function WithdrawalDetails({referralData,setRequests}) {
    const approveKYC = (referralID) =>{
        GetAPIMethodsStateless(`approve-withdrawal/${referralID}`,setRequests)
    }

    const declineKYC = (referralID) =>{
        GetAPIMethodsStateless(`decline-withdrawal/${referralID}`,setRequests)
    }
  return (
        <>
            <tr className='grid grid-cols-3 p-2 justify-evenly place-items-center w-full items-center border-y-2 border-y-[#E0E0E0] md:grid-cols-5'>
                <td className='font-medium text-sm max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px] md:text-base'>{referralData?.Account_name}</td>
                <td className='text-sm md:text-base'>
                    <div onClick={()=>{copyAccountNumber(referralData?.Account_number)}} className='flex space-x-2 cursor-pointer'>
                        <svg className="w-3 h-3 md:w-5 md:h-5" fill="none" stroke="#828282" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                        <h2 className='max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px]'>{referralData?.Account_number}</h2>
                    </div>
                    <h3 className='font-medium block md:hidden'>{referralData?.Bank_name}</h3>
                </td>

                
                <td className='text-sm hidden md:text-base md:block'>
                    <h3 className='font-medium'>{referralData?.Bank_name}</h3>
                </td>

                <td className='text-sm  md:text-base md:block'>
                    ₦{referralData?.amount?.toLocaleString()}
                </td>
                

                <td className='flex col-start-1 col-end-7 space-x-2 my-5 w-full justify-center md:col-start-auto md:col-end-auto md:space-x-0  md:space-y-2 md:flex-col'>
                    <button onClick={()=>{approveKYC(referralData?.withdrawal_id)}} className='bg-[#27AE60] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Approve</button>
                    <button onClick={()=>{declineKYC(referralData?.withdrawal_id)}} className='bg-[#EB5757] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Decline</button>
                </td>
            </tr>
        </>
  )
}
