import React from 'react';
import { returnStatusText } from './dashboardmisc';

export default function TradeInfoList({data,showPopUp}) {

  return (
    <div onClick={()=>{showPopUp(data?.order_id)}} className='flex items-center justify-between w-full p-4 cursor-pointer border-b border-b-gray-400 hover:bg-gray-50'>
        <div className='text-left'>
            <h4 className='font-semibold text-base md:text-xl'><span className={`${data?.order === "buy"?"text-green-500":"text-red-500"}`}>{data?.order === "buy"?"Buy":"Sell"}</span> {data?.coin_name}</h4>
            <h5 className='text-gray-500 font-medium text-xs md:text-sm'>Rate: ₦ {data?.exchange_rate}</h5>
            <h5 className='text-gray-500 font-medium text-xs md:text-sm'>Dollar value: ${data?.dollar_equivalent}</h5>
        </div>
        <div className='text-right '>
            <h4 className={`${returnStatusText(data?.status)[0]} font-normal text-sm md:text-base`}>{data?.status}</h4>
            <h5 className='text-black font-bold text-sm md:text-lg'>₦{data?.naira_equivalent.toLocaleString()}</h5>
            <h5 className='text-gray-500 font-normal text-xs md:text-sm'>{data?.status !== "Pending"? data?.date_completed :data?.date_placed}</h5>
        </div>
    </div>
  )
}
