import React,{useState} from 'react';
import { getFormValues,useTitle } from '../Misc/Misc';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import PutAPIMethod from '../../APIMethods/PutAPIMethod';

function ExchangeRate(props) {
    useTitle(`Gateway Exchange - Exchange Rate`);

    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const exchangeRate = GetAPIMethods("exchange-rate/");

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getFormValues()
        PutAPIMethod("exchange-rate/",formData,setRequests)
    }


    return (
        <div className='w-[90%] mx-auto my-32'>
            { ((exchangeRate.loading) || (request.loading)) && <GatewayLoader/>}

            <div className='w-full mx-auto max-w-lg'>
                <div className='w-full text-center mb-10'>
                    <h2 className='font-bold text-lg md:text-2xl'>Exchange Rate</h2>
                </div>

                <form onSubmit={handleSubmit} className='flex flex-col space-y-5'>
                    <div className="h-12 input-group !mb-0 md:h-14">
                        <input type="number" id="buy_value" defaultValue={exchangeRate.data?.buy_value} name='buy_value' placeholder='Buy Value (in naira)' required className="input h-full" autoComplete='off'/>
                        <label htmlFor="buy_value" className="input-label">Buy Value (in naira)</label>
                    </div>

                    <div className="h-12 input-group !mb-0 md:h-14">
                        <input type="number" id="sell_value" defaultValue={exchangeRate.data?.sell_value} name='sell_value' placeholder='Sell Value (in naira)' required className="input h-full" autoComplete='off'/>
                        <label htmlFor="sell_value" className="input-label">Sell Value (in naira)</label>
                    </div>

                    <div className='bg-[#0b193c] w-full mx-auto h-14 rounded-2xl md:h-16'>
                        <button className='text-white w-full h-full font-medium text-base md:text-xl'>Update Rate</button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default ExchangeRate;