import React from 'react'

export default function NetworkForm({handleSubmit,defaultData,editable=true,btnText="Add Network"}) {
  return (
    <form onSubmit={handleSubmit} className='flex flex-col space-y-5'>
            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="network_name" name='network_name' readOnly={!editable} defaultValue={defaultData?.network_name} placeholder='Network Name' required className="input h-full" autoComplete='off'/>
                <label htmlFor="network_name" className="input-label">Network Name</label>
            </div>

            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="network_address" name='network_address' defaultValue={defaultData?.network_address} placeholder='enter the general address for this network' required className="input h-full" autoComplete='off'/>
                <label htmlFor="network_address" className="input-label">Network Address</label>
            </div>


            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="memo" name='memo' defaultValue={defaultData?.memo} placeholder='Enter network Memo' className="input h-full" autoComplete='off'/>
                <label htmlFor="memo" className="input-label">Memo</label>
            </div>

            <div className='bg-[#0b193c] w-full mx-auto h-14 rounded-2xl md:h-16'>
                <button className='text-white w-full h-full font-medium text-base md:text-xl'>{btnText}</button>
            </div>

        </form>
  )
}
