import React,{useState,useEffect} from 'react';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import { PostAPIMethodStateless } from '../../APIMethods/PostAPIMethod';
import CustomSelect from '../HelperComponents/CustomSelectField/CustomSelect';
import { getFormValues,prettyNumber,copyReferralID,useTitle } from '../Misc/Misc';

function ReferralPage(props) {
    useTitle("Gateway Exchange - My Referrals");
    const [showBTN,setShowBTN] = useState(false);
    const [withdraw,setWithdraw] = useState(false);
    const [showError,setShowError] = useState(false);
    const Banks = ["UBA","Kuda","GTB","First Bank","Union Bank","PalmPay"];
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const referralInfo = GetAPIMethods("referral-info/");


    useEffect(()=>{
        if (referralInfo.data) {
            if (parseInt(referralInfo.data?.balance) > 1000) {
                setShowBTN(true);
            }
        }
    },[referralInfo.data])

    useEffect(()=>{
        if (request.data?.success) {
            window.location.reload()
        }
    },[request.data])


    
    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getFormValues();

        if (parseFloat(formData?.amount) > parseFloat(referralInfo.data?.balance)) {
            setShowError(true);
        } else {
            setShowError(false);
            PostAPIMethodStateless("withdraw-earnings/",formData,setRequests)
        }
    }

    return (
        <div className='w-full my-32'>
            { ((referralInfo.loading) || (request.loading)) && <GatewayLoader/>}
            <div className='w-full max-w-3xl p-5 bg-gray-100 rounded-2xl mx-auto'>

                <div className='max-w-[280px] w-full mx-auto p-5 bg-gray-100 rounded-2xl text-center'>
                    <div onClick={()=>{copyReferralID(referralInfo.data?.referral_id)}} className='flex space-x-2 w-full place-content-center items-center text-gray-400 cursor-pointer'>
                        <div>
                            <h5 className='font-bold text-[#FFA519] whitespace-nowrap text-lg md:text-xl'>{referralInfo.data?.referral_id}</h5>
                        </div>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                    </div>
                    <p className='font-medium text-xs md:text-sm text-gray-500'>Refferal ID</p>
                </div>

                <div className='flex justify-evenly items-center w-full mt-10'>
                    <div className='w-full'>
                        <h2 className='text-center font-bold text-lg md:text-2xl'>{prettyNumber(referralInfo.data?.referral_count)}</h2>
                        <h4 className='text-center font-medium text-sm md:text-base'>Total Referals</h4>
                    </div>
                    <div className='w-full'>
                        <h2 className='text-center font-bold text-lg md:text-2xl'>₦{prettyNumber(referralInfo.data?.balance)}</h2>
                        <h4 className='text-center font-medium text-sm md:text-base'>Referral Balance</h4>
                    </div>

                    <div className='w-full'>
                        <h2 className='text-center font-bold text-lg md:text-2xl'>₦{prettyNumber(referralInfo.data?.total_ref_earnings)}</h2>
                        <h4 className='text-center font-medium text-sm md:text-base'>Total Referal Earnings</h4>
                    </div>
                    
                </div>
            </div>
            {
                showBTN &&
            
                    <>
                        {
                            !withdraw &&
                    
                            <div className='p-5'>
                                <div onClick={()=>{setWithdraw(!withdraw)}} className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl my-5 md:h-16'>
                                    <button className='text-white w-full h-full font-medium text-base md:text-xl'>Request Withdrawal</button>
                                </div>
                            </div>
                        }
                     </>
            }

            {
                withdraw &&
                <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
                    <div className='text-center text-white'>
                        <h2 className='text-2xl font-semibold md:text-3xl'>Request Withdrawal</h2>
                    </div>
                    {
                        showError && <p className='text-red-600 font-medium text-sm md:text-base'>Can't withdraw more than your availbale referral balance</p>
                    }
                    <form className='w-full'>
                        <div className="h-12 input-group md:h-14">
                            <input type="number" id="amount" name='amount' placeholder='Amount to withdraw' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='new-password'/>
                            <label htmlFor="amount" className="input-label">Amount</label>
                            
                        </div> 

                        <div className="h-12 input-group md:h-14">
                            <input type="number" id="account_number" name='account_number' placeholder='Enter your account number' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='new-password'/>
                            <label htmlFor="account_number" className="input-label">Account Number</label>
                        </div>

                        <div className='h-16 w-full relative mb-10'>
                            <CustomSelect name={"bank_name"} Items={Banks} onChange={()=>{}} placeHolder={"Please select your bank"}/>
                        </div>

                        <div onClick={handleSubmit} className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                            <button className='text-white w-full h-full font-medium text-base md:text-xl'>Request Withdrawal</button>
                        </div>
                    </form>
                </div>

            }
        </div>
    );
}

export default ReferralPage;