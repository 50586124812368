import axios from "axios";

export const Image_URL = 'https://api.gatewaynetwork.net'
export const BASE_URL = 'https://api.gatewaynetwork.net/api/v1/';
export const LOCAL_URL = 'https://gatewaynetwork.net/';

// export const Image_URL = 'http://localhost:8000/'
// export const BASE_URL = 'http://localhost:8000/api/v1/';
// export const LOCAL_URL = 'http://localhost:3000/';

const axiosInstance = axios.create({
    baseURL:BASE_URL,
    timeout:50000,
    headers:{
        Authorization: localStorage.getItem['access_token']||localStorage.access_token
        ?
            `Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`
        :null,
        
        
        'Content-Type': 'application/json',
        accept: 'application/json',
    },

})


// axiosInstance.interceptors.request

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },function (error) {
    const originalRequest = error.config;
    console.log(error.response,'#######')

    if (error.response.status === 401 && originalRequest.url === BASE_URL+'refresh-token/') {
        window.location.href = `${LOCAL_URL}login`;
    }


    if (error.response.data.code === 'token_not_valid' && error.response.status === 401) {
                    
        const refreshToken = localStorage.getItem('refresh_token')||localStorage.refresh_token;
        if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
            //exp date in token is expressed in seconds,while now returns milliseconds:

            const now = Math.ceil(Date.now()/1000);
            
            if (tokenParts.exp > now) {
                return axiosInstance
                .post('/refresh-token/',{refresh:refreshToken})
                .then((response) =>{
                    let AuthTokens = response.data
                    localStorage.setItem('access_token',AuthTokens['access'])
                    localStorage.setItem('refresh_token',AuthTokens['refresh'])
                    // localStorage.setItem('user_groups',response.data['user_groups'])

                    axiosInstance.defaults.headers['Authorization'] = `Bearer ${AuthTokens['access']}`;

                    originalRequest.headers['Authorization'] = `Bearer ${AuthTokens['access']}`;

                    return(axiosInstance(originalRequest));
                })
                .catch((error) =>{
                    console.log(error);
                })
                
            } else{
                originalRequest.headers['Authorization'] = null
                localStorage.setItem('isAuthenticated',false)
                localStorage.setItem('access_token','')
                localStorage.setItem('refresh_token','')
                localStorage.setItem('user_groups','')
                window.location.href = `${LOCAL_URL}login`;
                // return(axiosInstance(originalRequest));

            }
        } else {
            window.location.href = `${LOCAL_URL}login`;
        }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });


export default axiosInstance;