import React from 'react';
import BuyPopUp from './BuyPopUp';
import SellPopUp from './SellPopUp';

export default function OrderInfoPopUp({orderInfo,onClick}) {
    const returnView = (orderInfo)=>{
        if (orderInfo.order === "sell"){
            return(<SellPopUp data={orderInfo} onClick={onClick}/>)
        }else{
            return(<BuyPopUp data={orderInfo} onClick={onClick}/>)
        }
    }
  return (
    <>
        {
            orderInfo.data && !orderInfo.error && returnView(orderInfo.data)
        }
    </>
  )
}
