const decimals = 0;

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true, 
            position: "bottom",
            align:'end',
        },
      title: {
        display: false,
      },    

      scales: {
        xAxis: [{
            gridLines: {
                
            }
        }],
        yAxes: [{
            gridLines: {
                
            },

            ticks: {
                beginAtZero: false,
              callback: function(value, index, values) {
              return '$' + value.toFixed(decimals);
              }
            }  
        }]
        }
    },
  };