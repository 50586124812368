import React from 'react';
import { useNavigate } from 'react-router-dom';

function IndexPage(props) {
    const navigateTo = useNavigate();
    return (
        <>
            <div className='flex flex-row flex-wrap justify-between place-items-center px-5 mt-24 space-y-10 w-full pt-24 mx-auto md:w-4/5 2xl:w-4/6'>
                <div className='w-full max-w-sm flex flex-col items-start space-y-5'>
                    <div className='w-full'>
                        <div>
                            <h2 className='text-[#ec752f] font-bold text-3xl break-words md:text-4xl'>Trading Cryptocurrencies</h2>
                            <h3 className='text-white font-bold text-lg'>have never been easier.</h3>
                        </div>
                        <div>
                            <h4 className='text-white font-medium text-base'>For <span className='text-[#FFA519]'>Safe Transactions</span></h4>
                            <h3 className='text-white font-medium text-base'>Gateway exchange got you covered</h3>
                        </div>
                    </div>

                    <div onClick={()=>{navigateTo("/dashboard")}} className='bg-[#ea7938] w-full max-w-xs h-14 rounded-2xl md:h-16'>
                        <button className='text-white w-full h-full font-medium text-base md:text-xl'>Trade Now</button>
                    </div>
                </div>
                <div className='max-w-lg w-full'>
                    <img src="/Image/IndexImages/p2p_exchange.svg" alt=""  className='w-full h-full'/>
                </div>
            </div>

            <div>
                <div className='transform rotate-180'>
                    <svg fill='#563d40' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                    </svg>
                </div>

                <div className='bg-[#563D40] py-12 rounded-br-[50px] rounded-bl-[50px]'>
                    <div>
                        <h3 className='text-[#ec752f] text-center font-bold text-2xl md:text-3xl'>Why Us?</h3>
                    </div>

                    <div className='grid grid-cols-1 gap-5 rounded-[20px] mx-auto place-items-center p-4 w-full md:grid-cols-2 md:w-4/5 2xl:w-4/6'>

                        <div className='bg-[#3b3343] flex flex-col w-full h-full justify-between p-5 rounded-[20px] max-w-[620px] my-[10px]'>
                            <div className='max-w-[80px] w-full h-full mx-auto'>
                                <img src="/Image/IndexImages/ease_to_use.svg" alt="ease_to_use" className='w-full h-full'/>
                            </div>
                            <div>
                                <h2 className='text-[#e57230] font-semibold text-center text-base md:text-xl'>Designed for ease of access</h2>
                            </div>
                            <div>
                                <p className='text-white font-medium text-sm text-center'>Our user interface is designed, with you in mind to give you that easy access to exchanging your tokens to cash or cash to token.</p>
                            </div>
                        </div>

                        <div className='bg-[#3b3343] flex flex-col w-full h-full justify-between p-5 rounded-[20px] max-w-[620px] my-[10px]'>
                            <div className='max-w-[80px] w-full h-full mx-auto'>
                                <img src="/Image/IndexImages/fast_transaction.svg" alt="fast_transaction" className='w-full h-full'/>
                            </div>
                            <div>
                                <h2 className='text-[#e57230] font-semibold text-center text-base md:text-xl'>Fast transactions</h2>
                            </div>
                            <div>
                                <p className='text-white font-medium text-sm text-center'>With us you don't have to worry about delays in your transaction we deliver faster than the hebrew women.</p>
                            </div>
                        </div>

                        <div className='bg-[#3b3343] flex flex-col w-full h-full justify-between p-5 rounded-[20px] max-w-[620px] my-[10px]'>
                            <div className='max-w-[80px] w-full h-full mx-auto'>
                                <img src="/Image/IndexImages/safe_and_secure.svg" alt="safe_and_secure" className='w-full h-full'/>
                            </div>
                            <div>
                                <h2 className='text-[#e57230] font-semibold text-center text-base md:text-xl'>Safe and Secure</h2>
                            </div>
                            <div>
                                <p className='text-white font-medium text-sm text-center'>With us you don't have to worry about losing you money we are legit, we are fast,we are Gateway.</p>
                            </div>
                        </div>

                        <div className='bg-[#3b3343] flex flex-col w-full h-full justify-between p-5 rounded-[20px] max-w-[620px] my-[10px]'>
                            <div className='max-w-[80px] w-full h-full mx-auto'>
                                <img src="/Image/IndexImages/customer_satisfaction.svg" alt="customer_satisfaction" className='w-full h-full'/>
                            </div>
                            <div>
                                <h2 className='text-[#e57230] font-semibold text-center text-base md:text-xl'>Customer Satisfaction</h2>
                            </div>
                            <div>
                                <p className='text-white font-medium text-sm text-center'>Besides safety,another thing we pride ourselves with is our customers satisfaction. We believe in customers first.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='bg-[#563D40] py-12 rounded-[50px] my-20'>
                <div>
                    <h3 className='text-[#ec752f] text-center font-bold text-2xl md:text-3xl'>About Us</h3>
                </div>

                <div className='text-white text-center font-medium text-sm px-5'>
                Gateway Exchange Network is a Crypto Exchange of repute.<br/>
                We pride ourselves to instantly deliver on both small and large orders efficiently.<br/>
                We do not delay, we fund your accounts instantly.We offer an intuitive, beginner friendly interface and 24/7 support.<br/>
                No prior trading experience required.
                </div>
            </div>
        </>
    );
}

export default IndexPage;