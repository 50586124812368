import React from 'react';
import './PortfolioLoader.css';

export default function PortfolioLoader() {
  return (
    <div className='bg-[#adadad8a] absolute h-full left-0 right-0 w-full grid place-content-center'>
        <div className="loader-portfolio"></div>
    </div>
  )
}
