import React,{useState,useEffect} from 'react';
import GetAPIMethods from '../../../../APIMethods/GetAPIMethod';

export default function TokenForm({handleSubmit,defaultData,editable=true,btnText="Add Token"}) {
    const [networks,setNetworks] = useState([]);
    const [selectedNetworks,setSelectedNetworks] = useState([]);

    const networkList = GetAPIMethods("network-list/");

    useEffect(()=>{
        if (networkList.data) {
            setNetworks(networkList.data?.map((net)=>{return(net?.network_name)}))
        }
    },[networkList.data])

    useEffect(()=>{
        if (defaultData?.network) {
            setSelectedNetworks(defaultData?.network)
        }
    },[defaultData?.network])
    

    const handleSelect = (e)=>{
        const result = networks.filter((network,id)=>{ return((network === e.target.value))});
        const resultX = selectedNetworks.filter((network,id)=>{ return((network !== e.target.value))});

        if (result.includes(e.target.value) && !selectedNetworks.includes(e.target.value)) {
            setSelectedNetworks(networks=>([...networks,e.target.value]))
        }else{
            setSelectedNetworks(resultX)
        }
                
    }

    
    return (
        <form onSubmit={handleSubmit} className='flex flex-col space-y-5'>
            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="token_name" name='token_name' readOnly={!editable} defaultValue={defaultData?.token_name} placeholder='Token Name' required className="input h-full" autoComplete='off'/>
                <label htmlFor="token_name" className="input-label">Token Name</label>
            </div>

            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="token_symbol" name='token_symbol' readOnly={!editable} defaultValue={defaultData?.token_symbol} placeholder='Eg:BTC,ETH or XRP' required className="input h-full" autoComplete='off'/>
                <label htmlFor="token_symbol" className="input-label">Token Symbol</label>
            </div>

            <div className='w-full'>
                <select name="network" id="network" onChange={(e)=>{handleSelect(e)}} required multiple value={selectedNetworks} className='bg-teal-50 px-5 py-3 text-[#828282]  rounded-2xl w-full outline-none text-sm md:text-lg'>
                    {
                        networks.map((network,key)=>{
                            return(<option key={key} value={network}>{network}</option>)
                        })
                    }
                </select>
            </div>

            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="lowest_buy_limit" name='lowest_buy_limit' defaultValue={defaultData?.lowest_buy_limit} placeholder='Lowest Buy limit (in usd)' required className="input h-full" autoComplete='off'/>
                <label htmlFor="lowest_buy_limit" className="input-label">Lowest Buy limit (in usd)</label>
            </div>


            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="highest_buy_limit" name='highest_buy_limit' defaultValue={defaultData?.highest_buy_limit} placeholder='Highest Buy limit (in usd)' required className="input h-full" autoComplete='off'/>
                <label htmlFor="highest_buy_limit" className="input-label">Highest Buy limit (in usd)</label>
            </div>

            <div className="h-12 input-group !mb-0 md:h-14">
                <input type="text" id="transfer_fee" name='transfer_fee' defaultValue={defaultData?.transfer_fee} placeholder='Transfer fee (in naira)' required className="input h-full" autoComplete='off'/>
                <label htmlFor="transfer_fee" className="input-label">Transfer fee (in naira)</label>
            </div>

            <div className='bg-[#0b193c] w-full mx-auto h-14 rounded-2xl md:h-16'>
                <button className='text-white w-full h-full font-medium text-base md:text-xl'>{btnText}</button>
            </div>

        </form>
    )
}
