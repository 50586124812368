import React,{useState} from 'react';
import TokenForm from './Forms/TokenForm';
import GatewayLoader from '../../Loader/GatewayLoader';
import GetAPIMethods from '../../../APIMethods/GetAPIMethod';
import { getAdvancedFormValues ,useTitle} from '../../Misc/Misc';
import {PutAPIMethodPlain} from '../../../APIMethods/PutAPIMethod';




export default function EditToken() {
    const [request,setRequests] = useState({loading:false,data:null,error:false,});
    const tokenName = window.location.href.split("/")?.at(-1);

    useTitle(`Gateway Exchange - Edit ${tokenName}`);

    const tokenInfo = GetAPIMethods(`get-token-info/${tokenName}`);

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getAdvancedFormValues()
        PutAPIMethodPlain("update-token/",formData,setRequests);
    }

    return (
        <div className='w-[90%] mx-auto my-32'>
            {(tokenInfo.loading || request.loading) && <GatewayLoader/>}
            <div className='w-full max-w-lg mx-auto p-5'>
                <div className='w-full text-center mb-10'>
                    <h2 className='font-bold text-lg md:text-2xl'>Edit {tokenName}</h2>
                </div>
                <TokenForm handleSubmit={handleSubmit} defaultData={tokenInfo.data} editable={false} btnText={`Edit ${tokenName}`}/>
            </div>
        
        </div>
    )
}
