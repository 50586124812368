import React from 'react';
import { useTitle } from '../Misc/Misc';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import TradeVolume from './DashboardComponents/TradeVolume';
import NumberOfTrades from './DashboardComponents/NumberOfTrades';


function AdminDashboard(props) {
    useTitle("Gateway Exchange - My Admin Dashboard");
    const OrderCount = GetAPIMethods("order-count/")

    return (
        <div className='w-[90%] mx-auto my-32'>
            { OrderCount.loading && <GatewayLoader/>}
            <div className='w-full mx-auto'>
                <h3 className='font-semibold text-lg md:text-2xl'>Good evening, Admin</h3>
                <p className='text-[#ff9a19] font-semibold text-xs md:text-sm'>How is your day going</p>
            </div>
            
            <div className='grid grid-cols-1 max-w-5xl w-full gap-5 my-10 md:grid-cols-2 xl:grid-cols-3'>
                <div className='text-[#ffa93d] w-full min-h-[5rem] h-full flex justify-between items-center px-5 rounded-xl cursor-pointer admin-box-shadow'>
                    <h2 className='font-bold text-base md:text-xl'>Pending Transaction</h2>
                    <p className='font-medium text-base md:text-xl'>{OrderCount.data?.pending}</p>
                </div>
                <div className='text-green-600 w-full min-h-[5rem] h-full flex justify-between items-center px-5 rounded-xl cursor-pointer admin-box-shadow'>
                    <h2 className='font-bold text-base md:text-xl'>Succesful transaction</h2>
                    <p className='font-medium text-base md:text-xl'>{OrderCount.data?.completed}</p>
                </div>
                <div className='text-red-600 w-full min-h-[5rem] h-full flex justify-between items-center px-5 rounded-xl cursor-pointer admin-box-shadow'>
                    <h2 className='font-bold text-base md:text-xl'>Failed transaction</h2>
                    <p className='font-medium text-base md:text-xl'>{OrderCount.data?.declined}</p>
                </div>
            </div>


            <div className='w-full grid gap-10 grid-cols-1 md:grid-cols-2'>

                <NumberOfTrades/>

                <TradeVolume/>
            </div>
        </div>
    );
}

export default AdminDashboard;